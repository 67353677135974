import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/Layout";
import ContactForm from "../components/common/ContactForm";
import "../styles/pages/contact.scss";
import { PrismicRichText } from "@prismicio/react";
import Seo from "../components/meta/Seo";

const Contact = ({ data }) => {
  const { page } = data;
  return (
    <div className="contact">
      <Seo page={page} />
      <Layout>
        <div className="contact-container max-width">
          <h1>{data.page.data.message.text}</h1>
          <div className="contact-info">
            <div className="contact-details">
              <PrismicRichText field={data.page.data.address.richText} />
              <PrismicRichText field={data.page.data.telephone.richText} />
              <PrismicRichText field={data.page.data.email.richText} />
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.8507955237!2d23.756044!3d37.9806112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bd5123b52d25%3A0xdc8bc3e8ccba230!2sSemitelou%206%2C%20Athina%20115%2028!5e0!3m2!1sen!2sgr!4v1686558793488!5m2!1sen!2sgr"></iframe>
            </div>
            <div className="contact-form">
              <ContactForm />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query ccontactPageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicContact(lang: { eq: $lang }, id: { eq: $id }) {
      url
      type
      data {
        meta_title
        meta_description
        address {
          richText
        }
        email {
          richText
        }
        message {
          richText
          text
        }
        telephone {
          richText
        }
      }
    }
  }
`;

export default Contact;
